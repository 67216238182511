<template>
  <div class="implementationSheet">
    <v-baseInfoCard :baseInfoList="baseInfoList"></v-baseInfoCard>
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :isShowHeadBtn="false"
      :isSearch="false"
      :isHead="false"
      v-bind="submitConfig"
      :beforeList="beforeList"
      :isOperateColumn="false"
      :afterReturnList="afterList"
    >
      <template #searchSlot>
        <v-select
          v-model="submitConfig.postData.queryData.tenantId"
          label="社区名称："
          :options="tenantName"
          @change="handelChange"
          placeholder="选择社区"
          @clear="submitConfig.postData.queryData.tenantId = null"
        />
        <v-select
          clearable
          v-model="submitConfig.postData.queryData.reportStatus"
          label="上报状态"
          :options="isSucceedStatusOps"
          placeholder="请选择"
          @change="$refs.list.getList()"
          @clear="submitConfig.postData.queryData.reportStatus = null"
        />
        <v-button text="查询" @click="goSearch"></v-button>
        <v-button
          v-if="isShowAddBtn"
          icon="el-icon-plus"
          text="新建"
          @click="handelAddClick"
        ></v-button>
      </template>
      <template #operateControl="scope">
        <v-button
          text="编辑"
          class="grayBtn"
          type="text"
          @click="editData(scope.row)"
        />
        <v-button text="查看" type="text" @click="viewData(scope.row)" />
        <v-button text="日志" type="text" @click="goViewLog(scope.row)" />
      </template>
    </v-list>
    <v-drawer
      :title="title"
      :isShowDrawer="isShowDrawer"
      @changeDrawer="changeDrawer"
    >
      <v-form
        :isEdit="isEdit"
        :isShowReportBtn="isShowReportBtn"
        :dataList="dataList"
        :fields="fieldList"
        @save="save"
        @report="report"
      ></v-form>
    </v-drawer>
  </div>
</template>

<script>
import {
  getFieldTypes,
  getTenantList,
  getComplexList,
  getPageListUrl,
  editDataUrl,
  getDataInfo,
  getAddData,
  getPushDataUrl,
} from "./api.js";
import { isSucceedStatusOps } from "./map.js";
import { createReportStatusHtml } from "@/utils/utils.js";
import { _localStorage } from "@/utils/utils.js";
import { mapState } from "vuex";
import { mapHelper } from "@/utils/common.js";
export default {
  name: "communityInfo",
  components: {},
  props: {},
  data() {
    return {
      submitConfig: {
        methods: "post",
        postData: {
          mapper: "OpsArchitectureCaseMapper",
          url: "scene/push/architecture_case",
          queryData: {
            tenantId: null,
            // organizationName: null,
            responseResult: null,
          },
        },
      },
      isShowAddBtn: false, //控制新建按钮
      title: "", //改动日志抽屉标题
      isEdit: true, //是否编辑
      isShowDrawer: false, //控制抽屉弹出
      isShowReportBtn: true, //显示上报按钮
      filterHeaders: [], //表头数据
      isSucceedStatusOps: isSucceedStatusOps(),
      baseInfoList: {
        reportCycle: "自动日报",
        apiName: "建筑案例信息上报接口",
        apiUrl: "https://统一开放网关地址/scene/push/architecture_case",
      },
      fieldList: [], //字段
      headers: [
        // {
        //   prop: "organizationName",
        //   label: "名称",
        //   show: true,
        //   align: "center",
        // },
        {
          prop: "tenantId",
          label: "社区名称",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            return this.formatTenantId(row, prop);
          },
        },
        // {
        //   prop: "isReport",
        //   label: "是否上报",
        //   align: "center",
        //   formatter: (row, prop) => {
        //     let opts = {};
        //     if (Object.prototype.hasOwnProperty.call(row, "isReport")) {
        //       if (row[prop] === 1) {
        //         opts.txt = "已上报";
        //       } else if (row[prop] === 0) {
        //         opts.txt = "未上报";
        //       }
        //       return createReportStatusHtml(this, row, prop, opts);
        //     } else {
        //       return "--";
        //     }
        //   },
        //   show: true,
        // },
        {
          prop: "reportTime",
          label: "上报时间",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        {
          prop: "reportStatus",
          label: "上报状态",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            let opts = {};
            if (row[prop] !== "") {
              if (row[prop] === 1) {
                opts.txt = "已上报";
              } else if (row[prop] === 0) {
                opts.txt = "失败";
              }
            } else {
              opts.txt = "未上报";
            }
            return createReportStatusHtml(this, row, prop, opts);
          },
        },
        {
          prop: "updateTime",
          label: "修改日期",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        {
          prop: "",
          label: "操作",
          align: "center",
          key: "operation",
          show: true,
          width: 150,
        },
      ],
      tableUrl: getPageListUrl,
      dataList: {}, //详情数据
      reportData: {
        id: "",
        tenantId: "",
        url: "scene/push/architecture_case",
      },
      isAddData: true, //是否编辑or新增
      tenantList: [], //社区编码列表
    };
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
    this.filterHeaders = this.headers;
    this.getFieldTypes();
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
    tenantName() {
      return this.tenantList.map((item) => {
        return { ...item, value: item.tenantId, label: item.tenantName };
      });
    },
  },
  mounted() {},
  methods: {
    afterList(arg) {
      arg.length > 0 ? (this.isShowAddBtn = false) : (this.isShowAddBtn = true);
      return arg;
    },
    async beforeList() {
      await this.getTenantList();
    },
    handelChange() {
      this.$refs.list.search();
    },
    //格式社区名称
    formatTenantId(row, prop) {
      const { map: tenantNamedMap } = mapHelper.setMap(this.tenantName);
      return tenantNamedMap[row[prop]] ? tenantNamedMap[row[prop]] : "--";
    },
    //获取字段
    getFieldTypes() {
      const params = {
        className: "OpsArchitectureCase",
      };
      this.$axios
        .post(getFieldTypes, this.$qs.stringify(params))
        .then((res) => {
          const { data } = res;
          this.fieldList = data;
        });
    },
    //获取社区编码列表
    async getTenantList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;
        this.submitConfig.postData.queryData.tenantId = data[0].tenantId;
        this.tenantList = data;
        data.forEach((item) => {
          item.value = item.futureCommunityCode;
          item.label = item.tenantName;
        });
      }
    },
    //新增
    handelAddClick() {
      this.title = "新增建筑案例";
      this.reportData.id = "";
      this.dataList = {};
      this.isShowDrawer = true;
      this.isEdit = true;
      this.isAddData = true;
      this.isShowReportBtn = false;
    },
    //重置
    reset() {
      this.submitConfig.postData.queryData.tenantId = null;
      // this.submitConfig.postData.queryData.organizationName = null;
      this.submitConfig.postData.queryData.reportStatus = null;
    },
    //查询
    goSearch() {
      this.$refs.list.getList();
    },
    //更新表头数据
    updateHeaders(arg) {
      this.filterHeaders = arg.filterHeaders;
      this.headers = arg.tableHeaders;
    },
    //编辑抽屉弹窗显示
    changeDrawer(v) {
      this.isShowDrawer = v;
    },
    //获取详情
    getDataDetail(id) {
      const params = {
        id,
        mapper: "OpsArchitectureCaseMapper",
      };
      this.$axios.post(getDataInfo, params).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data;
        } else {
          this.dataList = {};
        }
      });
    },
    //编辑数据
    async editData(row) {
      this.title = "编辑建筑案例";
      this.reportData.id = row.id;
      this.reportData.tenantId = row.tenantId;
      // this.reportData.url = row.url ? row.url : "scene/push/architecture_case";
      this.isEdit = true;
      this.isAddData = false;
      this.isShowReportBtn = true;
      await this.getDataDetail(row.id);
      this.isShowDrawer = true;
    },
    //编辑or新增
    save(data) {
      if (!this.isAddData) {
        data.id = this.reportData.id;
      } else {
        const currentIndex = this.tenantList.findIndex(
          (item) => item.futureCommunityCode == data.futureCommunityCode
        );
        if (currentIndex != -1) {
          data.tenantId = this.tenantList[currentIndex].tenantId;
        } else {
          data.tenantId = this.accountInfo.tenantId;
        }
      }
      const params = {
        mapper: "OpsArchitectureCaseMapper",
        data,
      };
      const url = this.isAddData ? getAddData : editDataUrl;
      this.$axios.post(url, params).then((res) => {
        if (res.code == 200) {
          this.isShowDrawer = false;
          this.$refs.list.getList();
          this.$message({
            message: this.isAddData ? "新增成功" : "编辑成功",
            type: "success",
            duration: 500,
          });
        }
      });
    },
    //上报
    report(data) {
      const params = {
        ...this.reportData,
        mapper: "OpsArchitectureCaseMapper",
        data,
      };
      this.$axios.post(getPushDataUrl, params).then((res) => {
        if (res.code == 200) {
          this.isShowDrawer = false;
          this.$refs.list.getList();
          this.$message({
            message: "上报成功",
            type: "success",
            duration: 500,
          });
        }
      });
    },
    //查看数据
    async viewData(row) {
      this.title = "查看建筑案例";
      this.isEdit = false;
      await this.getDataDetail(row.id);
      this.isShowDrawer = true;
    },
    //查看日志
    goViewLog(row) {
      this.$router.push({
        name: "viewLog",
        query: {
          id: row.id,
          url: "scene/push/architecture_case",
          tenantId: row.tenantId,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.implementationSheet {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 50px;
  ::v-deep .hc-list {
    flex: 1;
    .oTable {
      padding: 34px;
    }
    .search {
      background-color: #fff;
      box-sizing: border-box;
      margin-top: 10px;
      padding: 20px;
    }
  }
}
</style>
